.price-convertion-amount p span{
    font-weight: 600;
    color: var(--quaternary-color);
    font-size: 1em;
}

.transaction-upi-status{
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: flex-start;
}

.transaction-upi-status h5{
    margin-bottom: 0;
    font-size: 1em;
    font-weight: 600;
    color: var(--quaternary-color);
}

.transaction-upi-status h5 span {
    font-weight: 600;
    color: var(--tertiary-color);
}

.status-completed{
    color: #2DD11F!important;
}

.status-initated{
    color: #e0ad05!important;
}

.status-rejected{
    color: #F90000!important;
}

.list-table-sec.transaction-table-sec tbody td {
    width: auto;
}

.list-action-btn-sec .decline-btn {
    background-color: #F90000!important;
    padding: 0.5em 1.5em !important;
    text-align: center;
    transition: all 0.5s ease-in-out;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    /* box-shadow: 0 15px 30px 0 rgb(5 16 44 / 15%); */
    color: var(--secondary-color) !important;
    display: inline-block;
    border: none;
    font-size: 1em !important;
    font-weight: 600 !important;
    border: 1px solid transparent !important;
    width: 100%;
}

.transaction-table-sec .list-action-btn-sec{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1em;
    width: 100%;
}

.transaction-table-sec .list-action-btn-sec .buy-btn{
    width: 100%;
}

.transaction-table-sec{
    margin: 30px 0;
}

.setting-sec .nav-pills .nav-link.active{
    background-color: var(--primary-color);
    color: var(--secondary-color);
}

.setting-sec .nav-pills .nav-link{
    font-size: 1.1em;
    background-color: var(--secondary-color);
    font-weight: 600;
    color: var(--quaternary-color);
    padding: 1em 2em;
}

.setting-sec .nav-pills {
    gap: 1em;
    padding-bottom: 2em;
}

.payment-dispute {
    background-color:#FFFBF1;
    border-radius: 8px;
    padding: 0.5em 1.5em;
    font-weight: 600;
    font-size: 0.9em;
    text-align: center;
    color:#FFCE22;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap:0.5em;
    max-width: 150px;
    justify-content: center;
}

  .payment-rejected {
    background-color:#FFF6F6;
    border-radius: 8px;
    padding: 0.5em 1.5em;
    font-weight: 600;
    font-size: 0.9em;
    text-align: center;
    color:#FF6A6A;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap:0.5em;
    max-width: 150px;
    justify-content: center;
  }

  .payment-approved {
    background-color: #EAFFEC;
    border-radius: 8px;
    padding: 0.5em 1.5em;
    font-weight: 600;
    font-size: 0.9em;
    text-align: center;
    color:#197E23;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap:0.5em;
    max-width: 150px;
    justify-content: center;
  }

  .payment-pending{
    background-color: #EFF7FF;
    border-radius: 8px;
    padding: 0.5em 1.5em;
    font-weight: 600;
    font-size: 0.9em;
    text-align: center;
    color:#5C9EEB;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap:0.5em;
    max-width: 150px;
    justify-content: center;
  }