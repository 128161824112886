.static-pag-sec{
    margin-top: 4em;
    margin-bottom: 4em;
}

.section-title{
    text-align: center;
}

.section-title h1{
    color: var(--tertiary-color);
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 0.5em;
}

.section-title p{
    color: rgba(87, 87, 87, 1);
    font-size: 1em;
    max-width: 450px;
    margin: auto;
}

.static-box{
    background:var(--secondary-color);
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25); 
    padding: 3em;
    margin-top: 2em;      
}

.static-card h3{
    color: var(--tertiary-color);
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 1em;
}

.static-card p{
    color: rgba(87, 87, 87, 1);
    font-size: 0.9em;
}
